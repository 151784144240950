import { fileDownloadDialog } from '@/helpers/fileDownload';
import axios from 'axios';
import { formatBlobError } from './helper';

export function reformatURLForGoogleStorageCORS(url: string): string {
    const pieces = url.split('/');
    const storageName = pieces[2];
    const bucketName = pieces[3];

    const newBucketName = `${bucketName}.${storageName}`;

    return url.replace(`${storageName}/${bucketName}`, newBucketName);
}

function doesNeedURLFormattingForCORS(url: string): boolean {
    return url.includes('https://storage.googleapis.com/');
}

export async function downloadBlobFile({
    url,
    downloadFileName,
    method,
    data,
    headers
}: {
    url: string;
    downloadFileName: string;
    method: 'get' | 'post';
    data?: any;
    headers?: any;
}): Promise<void> {
    try {
        const { data: fileData } = await axios.request({
            url: doesNeedURLFormattingForCORS(url) ? reformatURLForGoogleStorageCORS(url) : url,
            method,
            responseType: 'blob',
            data,
            headers
        });

        fileDownloadDialog(fileData, downloadFileName);
    } catch (e) {
        throw await formatBlobError(e);
    }
}
