
import Vue from 'vue';
import { mapMutations } from 'vuex';
import Messenger from '@/views/communication/Messenger.vue';
import SubmitButton from '@/components/SubmitButton.vue';
import { IAccountingOfficeViewModel } from 'dexxter-types';
import { getAccountantOfUserRequest } from '@/services/accountants';
import { isNil } from 'lodash';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

export default Vue.extend({
    name: 'OpenMessengerModalButton',
    components: {
        SubmitButton
    },
    props: {
        polymorphicDocumentId: {
            type: Number
        },
        documentId: {
            type: Number
        }
    },
    data() {
        return {
            accountant: null as null | IAccountingOfficeViewModel
        };
    },
    computed: {
        userHasAccountant(): boolean {
            return !isNil(this.accountant);
        }
    },
    async created() {
        try {
            this.accountant = await getAccountantOfUserRequest();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        }
    },
    methods: {
        ...mapMutations('modal', ['setModalContent']),
        async openCommunicationModal(): Promise<void> {
            this.setModalContent({
                component: Messenger,
                props: {
                    documentId: this.documentId,
                    polymorphicDocumentId: this.polymorphicDocumentId
                }
            });
        }
    }
});
