
import Vue, { PropOptions } from 'vue';
import { downloadBlobFile } from '@/services/utils';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { getRemainingWindowHeight } from '@/helpers/styleHelpers';

export default Vue.extend({
    name: 'FileViewer',
    props: {
        src: {
            required: true,
            type: String
        } as PropOptions<string>,
        downloadUrl: {
            required: false,
            type: String
        } as PropOptions<string | null>,
        fileName: {
            required: true,
            type: String
        } as PropOptions<string>,
        downloadFileName: {
            required: false,
            type: String
        } as PropOptions<string | null>,
        type: {
            required: false,
            type: String,
            default: 'image'
        } as PropOptions<'image' | 'html'>
    },
    data() {
        return {
            scale: 0.9 as number,
            rotation: 0 as number,
            wrapperWidth: 0 as number,
            wrapperHeight: 0 as number,
            initialHeight: 0 as number,
            initialWidth: 0 as number,
            translateX: 0 as number,
            translateY: 0 as number,
            isDownloading: false
        };
    },
    computed: {
        cssVars(): {
            '--scale': string;
            '--rotation': string;
            '--wrapper-width': string;
            '--wrapper-height': string;
            '--translate-x': string;
            '--translate-y': string;
        } {
            return {
                '--scale': `scale(${this.scale})`,
                '--rotation': `rotate(${this.rotation}deg)`,
                '--wrapper-width': `${this.wrapperWidth}px`,
                '--wrapper-height': `${this.wrapperHeight}px`,
                '--translate-x': `${this.translateX}px`,
                '--translate-y': `${this.translateY}px`
            };
        },
        displayFileName(): string {
            const MAX_FILENAME_LENGTH = 22;
            if (this.fileName.length < MAX_FILENAME_LENGTH) {
                return this.fileName;
            }
            return `${this.fileName.substr(0, MAX_FILENAME_LENGTH / 2)}...${this.fileName.substr(
                -MAX_FILENAME_LENGTH / 2
            )}`;
        }
    },
    mounted(): void {
        const contentWrapperRef = this.$refs.contentWrapper as HTMLDivElement;
        this.wrapperWidth = contentWrapperRef.offsetWidth;
        this.wrapperHeight = getRemainingWindowHeight(contentWrapperRef, 6);

        if (this.type === 'image') {
            this.createImageElement();
        } else if (this.type === 'html') {
            this.createIframe();
        }
    },
    methods: {
        increaseImageSize(): void {
            this.scale += this.scale < 2 ? 0.1 : 0;
            this.adjustTranslate();
        },
        decreaseImageSize(): void {
            this.scale -= this.scale > 0.2 ? 0.1 : 0;
            this.adjustTranslate();
        },
        adjustTranslate() {
            if (this.initialWidth * this.scale > this.wrapperWidth) {
                this.translateX = (this.initialWidth * this.scale - this.wrapperWidth) / 2;
            } else {
                this.translateX = 0;
            }
            if (this.initialHeight * this.scale > this.wrapperHeight) {
                this.translateY = (this.initialHeight * this.scale - this.wrapperHeight) / 2;
            } else {
                this.translateY = 0;
            }
        },
        rotateClockwise(): void {
            this.rotation += 90;
            this.adjustOrientation();
        },
        rotateCounterClockwise(): void {
            this.rotation -= 90;
            this.adjustOrientation();
        },
        adjustOrientation() {
            if (this.type === 'image') {
                const imageElement = document.getElementById('imageElement');

                if (imageElement) {
                    const previousWidth = this.initialWidth;
                    this.initialWidth = this.initialHeight;
                    this.initialHeight = previousWidth;

                    this.adjustTranslate();
                }
            }
        },
        async downloadFile(): Promise<void> {
            try {
                this.isDownloading = true;
                await downloadBlobFile({
                    url: this.downloadUrl ?? this.src,
                    downloadFileName: this.downloadFileName ?? this.fileName,
                    method: 'get'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.isDownloading = false;
            }
        },
        createImageElement(): void {
            const image: HTMLImageElement = new Image();
            image.id = 'imageElement';
            image.src = this.src;
            image.onload = () => this.drawImageOnPage(image);
        },
        drawImageOnPage(image: HTMLImageElement): void {
            this.setInitialSizes(image);

            // Important that the element is drawn after the image is loaded, because the sizes are unkown
            this.addElementToContentWrapper(image);
        },
        setInitialSizes(image: HTMLImageElement): void {
            const ratio = image.height / image.width;

            if (image.height > image.width) {
                this.initialHeight = this.wrapperHeight;
                this.initialWidth = this.wrapperHeight / ratio;
                image.className = 'portrait';
            } else {
                this.initialWidth = this.wrapperWidth;
                this.initialHeight = this.wrapperWidth * ratio;
                image.className = 'landscape';
            }
        },
        createIframe(): void {
            const iframe: HTMLIFrameElement = document.createElement('iframe');

            iframe.srcdoc = this.src;
            iframe.id = 'iframeElement';
            iframe.height = '100%';
            iframe.width = '100%';
            iframe.setAttribute('style', 'overflow: visible');
            iframe.onload = () => this.setIframeContentSize(iframe);

            this.addElementToContentWrapper(iframe);
        },
        setIframeContentSize(iframe: HTMLIFrameElement) {
            if (iframe && iframe.contentWindow) {
                const bodyScrollWidth = iframe.contentWindow.document.body.scrollWidth;
                const bodyScrollHeight = iframe.contentWindow.document.body.scrollHeight;
                const EXTRA_PADDING = 20;

                this.initialWidth = bodyScrollWidth;
                this.initialHeight = bodyScrollHeight;
                this.adjustTranslate();

                iframe.setAttribute('style', `height: ${bodyScrollHeight + EXTRA_PADDING}px`);
            }
        },
        addElementToContentWrapper(element: HTMLImageElement | HTMLIFrameElement) {
            const contentWrapper: HTMLDivElement = this.$refs.contentWrapper as HTMLDivElement;
            if (contentWrapper) contentWrapper.appendChild(element);
        }
    }
});
