<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ title }}
                </template>
            </page-header>
        </template>
        <template #content>
            <v-row v-if="currentInvoicable">
                <v-col cols="12" :lg="isAccountant(currentUserData) ? 12 : 9">
                    <invoicable-media-viewer
                        v-if="canShowInvoicableMedia"
                        :current-invoicable="currentInvoicable"
                        :invoicable-type="invoicableType"
                    />
                    <loader v-else />
                </v-col>
                <v-col v-if="!isAccountant(currentUserData)" cols="12" lg="3">
                    <slot name="actions" />
                </v-col>
            </v-row>
        </template>
    </view-layout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import InvoicableMediaViewer from './InvoicableMediaViewer.vue';
import { isNil } from 'lodash';
import { mapState } from 'vuex';

export default {
    components: {
        ViewLayout,
        PageHeader,
        InvoicableMediaViewer
    },

    mixins: [formRulesMixin, screenSizeMixin],

    props: {
        loading: {
            required: true,
            type: Boolean
        },
        title: {
            required: true,
            type: String
        },
        currentInvoicable: {
            required: true
        },
        invoicableType: {
            required: true
        }
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canShowInvoicableMedia() {
            return this.invoicableHasMedia;
        },
        invoicableHasMedia() {
            return isNil(this.currentInvoicable.url) === false;
        }
    }
};
</script>
