export function fileDownloadDialog(fileData: any, name: string): void {
    const downloadUrl: string = URL.createObjectURL(new File([fileData], name));

    const link: HTMLAnchorElement = document.createElement('a');
    link.href = downloadUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
