<template>
    <component :is="viewComponent" :src="currentInvoicable.url" :file-name="getFileName" />
</template>

<script>
import FileViewer from '@/components/FileViewer.vue';
import PDFViewer from '@/components/PDFViewer.vue';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { getPdfRepresentationOfCreditnoteUrl } from '@/services/creditnote';
import { getPdfRepresentationOfInvoiceUrl } from '@/services/invoice';
import { getPdfRepresentationOfQuotationUrl } from '@/services/quotation';
import { isNil } from 'lodash';
import mime from 'mime-types';

export default {
    components: {
        PDFViewer
    },
    mixins: [screenSizeMixin],

    props: {
        currentInvoicable: {
            required: true
        },
        invoicableType: {
            required: true
        }
    },

    computed: {
        viewComponent() {
            if (this.isPDFFile(this.currentInvoicable.url)) {
                return PDFViewer;
            }

            return FileViewer;
        },
        invoicableDownloadPdfUrl() {
            if (isNil(this.currentInvoicable)) {
                return null;
            }

            return this.getPdfRepresentationOfInvoicableUrl();
        },
        getFileName() {
            if (isNil(this.currentInvoicable)) {
                return null;
            }

            return `${this.$t(`general.${this.invoicableType}`)} - ${
                this.currentInvoicable[`${this.invoicableType}Number`]
            }`;
        },
        downloadFileName() {
            if (isNil(this.currentInvoicable)) {
                return null;
            }

            return `${this.$t(`general.${this.invoicableType}`)} - ${
                this.currentInvoicable[`${this.invoicableType}Number`]
            }.pdf`;
        }
    },

    methods: {
        isPDFFile(fileURL) {
            if (isNil(fileURL)) {
                throw new Error('Empty file url');
            }
            return mime.lookup(fileURL) === 'application/pdf';
        },
        getPdfRepresentationOfInvoicableUrl() {
            if (this.invoicableType === 'invoice') {
                return getPdfRepresentationOfInvoiceUrl(this.currentInvoicable.id);
            } else if (this.invoicableType === 'creditnote') {
                return getPdfRepresentationOfCreditnoteUrl(this.currentInvoicable.id);
            } else if (this.invoicableType === 'quotation') {
                return getPdfRepresentationOfQuotationUrl(this.currentInvoicable.id);
            }
        }
    }
};
</script>
